<template>
  <b-card class="flex-lg-fill flex-sm-shrink-1 cpt-treeview-item d-inline-flex"
          :border-variant="isSelected ? 'primary' : ''">
    <!--<div class="position-absolute ml-0">-->
    <!--  <div class="tree-line-corner"></div>-->
    <!--  <div class="tree-line-end"></div>-->
    <!--</div>-->
    <b-button pill class="toggle-plus-button ml-1" variant="outline-secondary" title="Load children"
              @click.stop="loadChildren">
      <span v-if="isLoading"><b-spinner small type="grow" /></span>
      <span v-else>
        <feather-icon v-if="resultsLength > 0" :class="visible ? 'icon-rotated' : ''"
                      icon="ChevronRightIcon"></feather-icon>
        <feather-icon v-else icon="RefreshCcwIcon"></feather-icon>
      </span>
    </b-button>
    <span class="ml-1 mr-1">-</span>
    <b-badge variant="info" class="ml-1 mr-1" v-if="resultsLength > 0" title="Children">{{ resultsLength }}</b-badge>
    <b-badge v-for="l in cpt.labels.filter(lc => lc !== 'Component' && !lc.startsWith('RBAC_'))" :key="l"
             class="mr-1"
             variant="primary"
    >
      ({{ l }})
    </b-badge>
    <span :text-variant="isSelected ? 'primary' : 'default'" @click.stop.self="cptClicked(cpt)">
      {{ cpt.task_nr }} {{ ' ' }}{{ cpt.qualified_name }}
    </span>
    <b-button variant="outline-secondary" size="sm" class="ml-lg-2 ml-md-1"
              title="Show in Ontology graph"
              @click.self="routeTo(cpt)">
      Graph
    </b-button>
    <b-button variant="outline-secondary" size="sm" class="ml-md-1 mr-1"
              title="Show Details Sidebar"
              @click.stop.self="cptClicked(cpt)">
      Details
    </b-button>
    <b-collapse :visible="visible && resultsLength > 0">
      <hr>
      <div v-if="isLoading">
        <b-spinner small type="grow" />
      </div>
      <b-list-group class="tree-children" v-else>
        <template v-for="c in results">
          <ontology-tree-view-component :key="c.id" :cpt="c" @cptClicked="cptClicked" />
        </template>
      </b-list-group>
    </b-collapse>
  </b-card>
</template>
<script>
import { useRouter } from '@core/utils/utils'
import {
  onMounted,
  ref,
  computed,
  watch,
} from '@vue/composition-api'
import coreService from '@/libs/api-services/core-service'
import store from '@/store'

export default {
  name: 'OntologyTreeViewComponent',
  props: {
    cpt: {
      required: true,
      type: Object,
    },
  },
  setup(props, context) {
    const { route, router } = useRouter()
    const results = ref([])
    const infoText = ref('')
    const modelId = ref('')
    const visible = ref(false)
    const pinned = ref(false)
    const isLoading = ref(false)
    const selectedEntity2 = computed(() => store.state.domainModel.selected_entity2)
    const isSelected = computed(() => selectedEntity2.value?.context?.details.id === props.cpt.id)
    onMounted(() => {
      modelId.value = route.value.params.modelId
    })

    function routeTo(component) {
      router.push({
        name: 'domain_ontology_focus_with_root',
        params: getParams(component),
      })
    }

    function getParams(component) {
      return {
        modelId: modelId.value,
        root: component.id,
        focus: component.id,
      }
    }

    async function cptClicked(passedCpt) {
      if (passedCpt) {
        await store.dispatch('domainModel/selectEntity2', passedCpt.id)
      }
      context.emit('cptClicked')
    }

    const resultsLength = computed(() => results.value.length)

    async function loadChildren() {
      visible.value = !visible.value
      if (visible.value) {
        isLoading.value = true
        const result = await coreService.componentApi.getComponentChildren(props.cpt.id, modelId.value)
        results.value = result.sort((a, b) => a.task_nr > b.task_nr)
        isLoading.value = false
      }
    }

    return {
      modelId,
      results,
      routeTo,
      infoText,
      loadChildren,
      visible,
      isLoading,
      resultsLength,
      cptClicked,
      selectedEntity2,
      isSelected,
    }
  },
}
</script>
<style lang="scss">
.toggle-plus-button {
  font-weight: bold;
  padding: 6px;
  border-radius: 1rem;
  height: 2.5rem;
  width: 2.5rem;
  font-size: larger;
}

.cpt-treeview-item.list-group-item:active {
  background-color: inherit !important;
}

.icon-rotated {
  transform: rotate(90deg);
  transition: all linear 0.25s;
}

.tree-line-corner {
  width: 3rem;
  height: 1.5rem;
  border: 1px solid grey;
  border-top: none;
  border-right: none;
}

.tree-line-end:not(.cpt-treeview-item:last-child .tree-line-end) {
  width: 3rem;
  height: 3.5rem;
  border-left: 1px solid grey;
}

.cpt-treeview-item .card-body {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.6rem;
  margin-top: 0;
  margin-bottom: 0;
}
.custom-control-label {
  margin-left: 0.5rem;
  margin-right: 1rem;
}
</style>
