<template>
  <div>
    <b-container class="container-fluid">
      <div class="p-2">
        <b-input-group class="half-width">
          <b-form-input ref="searchInput" placeholder="Search..." v-model="term" :focus="true" debounce="200" @keyup.enter="doSearch" />
          <b-input-group-append>
            <b-button variant="primary" type="button" role="button" @click="doSearch">
              Search
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <b-input-group class="half-width">
          <b-form-checkbox-group v-model="selectedLabels" class="p-1" :options="allLabels" />
        </b-input-group>
        <hr>
        <p>{{ infoText }}</p>
      </div>
      <b-list-group class="h-75" style="overflow-y: auto">
        <template v-for="c in pinnedResults">
          <Transition :key="c.id">
            <b-card class="result-card pinned-result" border-variant="info" :key="c.id" :id="c.id">
              <b-button pill size="sm" class="d-inline-block" variant="outline-secondary" @click="togglePin(c)">
                <b-icon-pin-fill></b-icon-pin-fill>
              </b-button>
              <ontology-tree-view-component :key="c.id" :cpt="c" @cptClicked="componentClicked(c)" />
            </b-card>
          </Transition>
        </template>
      </b-list-group>
      <b-list-group class="h-75" style="overflow-y: auto">
        <template v-for="c in filteredResults">
          <Transition :key="c.id">
            <b-card class="result-card pinned-result" :key="c.id" :id="c.id">
              <b-button pill size="sm" class="d-inline-block" variant="outline-secondary" @click="togglePin(c)">
                <b-icon-pin-angle></b-icon-pin-angle>
              </b-button>
              <ontology-tree-view-component :key="c.id" :cpt="c" @cptClicked="componentClicked(c)" />
            </b-card>
          </Transition>
        </template>
      </b-list-group>
    </b-container>
    <b-sidebar
      title="Entity Details"
      bg-variant="white"
      width="30vw"
      right
      :visible="isSidebarVisible"
      @hidden="isSidebarVisible = false"
    >
      <EntityDetails @clicked="doUpdate()" />
    </b-sidebar>
  </div>
</template>
<script>
import { useRouter } from '@core/utils/utils'
import {
  ref, onMounted, watch, computed,
} from '@vue/composition-api'
import coreService from '@/libs/api-services/core-service'
import Fuse from 'fuse.js'
import OntologyTreeViewComponent from '@/components/Domain/OntologyTreeView/OntologyTreeViewCptCard.vue'
import EntityDetails from '@/components/Domain/EntityDetails.vue'
import { BIconPin } from 'bootstrap-vue'

export default {
  name: 'OntologyTreeView',
  components: { EntityDetails, OntologyTreeViewComponent },
  setup() {
    const { route, router } = useRouter()
    const results = ref([])
    const dbResults = ref([])
    const pinnedResults = ref([])
    const term = ref('')
    const infoText = ref('')
    const modelId = ref('')
    const searchInput = ref(null)
    const allLabels = ref([])
    const selectedLabels = ref([])
    const isSidebarVisible = ref(false)
    onMounted(() => {
      modelId.value = route.value.params.modelId
      searchInput.value.focus()
    })

    // FilteredResults: only want results where labels are not RBAC and have one of the ticked labels
    // AND they are not in the pinned results, which is iterated separately
    const filteredResults = computed(() => results.value.filter(cpt => cpt.labels
      .filter(lc => lc !== 'Component' && !lc.startsWith('RBAC_'))
      .find(cl => selectedLabels.value.includes(cl))
      && !pinnedResults.value.find(pr => pr.id === cpt.id)))

    async function doSearch() {
      if (term.value.length < 3) {
        infoText.value = 'Search term must be longer than 2 characters'
        return
      }
      dbResults.value = await coreService.componentApi.findComponents(term.value, route.value.params.modelId)
      infoText.value = `${dbResults.value.length} result(s)`
      results.value = dbResults.value
      const tempLabels = []
      results.value.forEach(cpt => {
        tempLabels.push(...cpt.labels.filter(lc => lc !== 'Component' && !lc.startsWith('RBAC_')))
      })
      allLabels.value = [...new Set(tempLabels)]
      if (selectedLabels.value.length === 0) {
        // only set the selectedLabels checkboxes to all ticked if they have never been changed
        selectedLabels.value = allLabels.value
      }
    }

    function routeTo(component) {
      router.push({
        name: 'domain_ontology_focus_with_root',
        params: getParams(component),
      })
    }

    function getParams(component) {
      return {
        modelId: modelId.value,
        root: component.id,
        focus: component.id,
      }
    }

    watch(term, fuseFilter)

    function fuseFilter(term) {
      if (term === '') {
        results.value = dbResults.value
        infoText.value = `${dbResults.value.length} result(s)`
        return
      }
      const options = {
        includeScore: true,
        keys: ['name'],
      }
      const fuse = new Fuse(results.value, options)
      results.value = fuse.search(term).map(e => e.item).slice()
      if (results.value.length !== dbResults.value.length) {
        infoText.value = `${dbResults.value.length} result(s), ${results.value.length} after filtering`
      }
    }

    async function componentClicked(cpt) {
      isSidebarVisible.value = true
    }

    function togglePin(c) {
      c.pinned = !c.pinned ?? false
      const index = pinnedResults.value.findIndex(r => r.id === c.id)
      if (index > -1) {
        pinnedResults.value.splice(index, 1)
      } else {
        pinnedResults.value.push(c)
      }
    }

    return {
      modelId,
      results,
      term,
      doSearch,
      routeTo,
      infoText,
      fuseFilter,
      searchInput,
      allLabels,
      selectedLabels,
      filteredResults,
      pinnedResults,
      componentClicked,
      isSidebarVisible,
      togglePin,
    }
  },
}
</script>
<style lang="scss">
  .result-card .card-body {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    margin-top: 0;
    margin-bottom: 0;
  }

.v-enter-active,
.v-leave-active {
  transition: transform 0.1s ease;
}

.v-enter-from,
.v-leave-to {
  transform: translateY(-100px);
}
</style>
